// =============================================================================
// Custom properties (variables) for custom colors, including the brand colors,
// primary and secondary palettes.
// =============================================================================

:root {
  --color-primary: #000000;
  --color-grey: #939393;
  --color-grey-dark-2: #3d3d3d;
  --color-grey-dark: #868686;
  --color-grey-mid: #d4d4d4;
  --color-grey-light: #f0f0f0;
  --color-white: #ffffff;
  --color-white-transparent: rgba(255, 255, 255, 0.3);
  --color-red: #ff0000;
  --color-blue: #46c5e2;
  --color-blue-dark: #003462;
  --color-pink-opacity: hsla(322.26, 98.41%, 50.59%, 0);
  --color-pink: hsla(322.26, 98.41%, 50.59%, 0.4);
}
