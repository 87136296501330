$root-font-base-size: 24px;
$root-font-base-phone-size: 18px;
$root-font-base-line-height: 1.2;
$root-font-min-size: 10px;
$root-font-max-size: 100px;
$design-width: 1440px;

$breakpoint-sm: 500px;
$breakpoint-md: 800px;
$breakpoint-lg: 1220px;
$breakpoint-xl: 1440px;
$breakpoint-xxl: 1600px;

$max-width: 1800px;

$default-opacity: 0.6;

$svg-height: 495;
$svg-width: 1404;

$black: var(--color-primary);
$white: var(--color-white);
$white-transparent: var(--color-white-transparent);

$red: var(--color-red);
$blue: var(--color-blue);
$blue-dark: var(--color-blue-dark);
$grey: var(--color-grey);
$grey-dark: var(--color-grey-dark);
$grey-light: var(--color-grey-light);

$base-desktop-margin: 40px;
// $desktop-inner: $design-width - $base-desktop-margin * 2;

$unitless-scaling-margin: $base-desktop-margin / $design-width * 100;
$desktop-margin: rem-property($base-desktop-margin);

$tablet-margin: 25px;
// $tablet-inner: $tablet-portrait - $tablet-margin * 2;

$phone-margin: 10px;
// $phone-inner: $phone-portrait - $phone-margin * 2;

$phone-margin-vert: $phone-margin * 6;

$small-nav-margin: 20px;

$phone-header-height: calc(#{$phone-margin} * 2 + 33px);

$base-logo-height: 54px;
$base-logo-small-height: 51px;
$base-logo-small-height-phone: 40px;
$logo-height: rem-property($base-logo-height);
$logo-small-height: rem-property($base-logo-small-height);
$logo-small-height-phone: rem-property($base-logo-small-height-phone);

$header-height: rem-property(
  $base-logo-height + $base-desktop-margin + $base-desktop-margin
);
$header-small-height: $base-logo-small-height-phone + $small-nav-margin +
  $small-nav-margin;

$standard-border: rem-property(1px) solid $white;
$black-border: rem-property(1px) solid $black;

:export {
  phoneMax: $breakpoint-md;
  designWidth: $design-width;
  //   maxWidth: $max-width;

  desktopMargin: $desktop-margin;
  phoneMargin: $phone-margin;

  phoneHeaderHeight: $phone-header-height;
  headerHeight: $header-height;
  headerSmallHeight: $header-small-height;

  rootFontBaseSize: $root-font-base-size;

  //   transitionTime: $transitionTime;
}
