@import '../vars/constants';


/*
  breakpoints by default have a min AND a max if you want to have one
  and not the other, prefix/suffix either end with a _ eg. md_
*/ 


@mixin _sm {
  @media (max-width: #{$breakpoint-md - 1}) {
    @content;
  }
}
@mixin md {
  @media (min-width: $breakpoint-md) and (max-width: #{$breakpoint-lg - 1}) {
    @content;
  }
}
@mixin md_ {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin _md {
  @media (max-width: #{$breakpoint-lg - 1}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: $breakpoint-lg) and (max-width: #{$breakpoint-xl - 1}) {
    @content;
  }
}
@mixin lg_ {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}
@mixin _lg {
  @media (max-width: #{$breakpoint-xl - 1}) {
    @content;
  }
}
@mixin xl {
  @media (min-width: $breakpoint-xl) and (max-width: #{$breakpoint-xxl - 1}) {
    @content;
  }
}
@mixin xl_ {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}
@mixin _xl {
  @media (max-width: #{$breakpoint-xxl - 1}) {
    @content;
  }
}
@mixin xxl_ {
  @media (min-width: $breakpoint-xxl) {
    @content;
  }
}

@mixin mouse-device {
  @media (hover: hover) {
    @content;
  }

  @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   // IE11 doesn't support @media(hover), so it just gets the mouse behaviour
   @content;
  }
}

@mixin touch-device {
  @media (hover: none) {
    @content;
  }
}
