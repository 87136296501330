.fade {
  transition: opacity 0.6s;
  &.enter,
  &.exit {
    opacity: 0;

    :global {
      .slide-counter {
        display: none;
      }
    }
  }

  &.faded {
    opacity: 0;

    :global {
      .slide-counter {
        display: none;
      }
    }
  }

  &.enterActive,
  &.exitActive {
    overflow: hidden;
  }

  &.enterActive,
  &.enterDone {
    opacity: 1;
  }
}
