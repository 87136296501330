// Base styles that aren't a component, a utility, a reset thing, etc...

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-family: var(--font-sans);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
}

html.is-snapping {
  @include md_ {
    scroll-snap-type: y mandatory;
  }
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  opacity: 0;
  transition: opacity 300ms;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[role='button'],
button {
  cursor: pointer;
}

body.is-fonts-loaded {
  opacity: 1;
}

.outline-none {
  outline: none;
}

#klarna-placement-loretwo::part(osm-container) {
  background-color: transparent;
  padding: 0;
  flex-direction: row;
  align-items: flex-start;
}

#klarna-placement-loretwo::part(osm-message) {
  margin-left: 14px;
  margin-right: 0px;
  text-align: right;

  margin-top: rem-property(2px);
  line-height: 1.2;
  font-size: rem-property(16px);
  font-family: var(--font-primary);
  display: flex;
  white-space: nowrap;

  @include md_ {
    display: block;
    white-space: unset;
    width: rem-property(190px);
    margin-left: rem-property(10px);
  }
}

#klarna-placement-loretwo::part(osm-cta) {
  opacity: 0.5;
  line-height: 1.2;
  font-size: rem-property(16px);
  font-family: var(--font-primary);
  margin-left: 4px;

  @include md_ {
    margin-left: 0;
  }

  &:hover {
    opacity: 1;
  }
}

#klarna-placement-loretwo::part(osm-badge) {
  height: 24px;
  @include md_ {
    height: rem-property(24px);
    //   width: auto;
  }
}
