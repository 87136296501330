@mixin webfont($name, $filename, $weight: 400, $style: normal) {
  // sass-lint:disable-all
  @font-face {
    font-family: '#{$name}';
    src: url('./fonts/' + $filename + '.woff2') format('woff2'),
      url('./fonts/' + $filename + '.otf') format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
}

@include webfont('NeueMontreal', 'NeueMontreal-Regular', 400);
@include webfont('NeueMontreal', 'NeueMontreal-Bold', 700);
@include webfont('AtlasTypewriter', 'AtlasTypewriter-Regular', 400);
