@use 'sass:math';

@function em-property($px-value, $parent-font-px-size: $root-font-base-size) {
  @return (math.div($px-value, $parent-font-px-size) * 1em);
}

@function rem-property($px-value) {
  @return (math.div($px-value, $root-font-base-size) * 1rem);
}

@mixin vw-font-size(
  $px-size,
  $min-px-size: null,
  $max-px-size: null,
  $design-width: $design-width
) {
  @if not $min-px-size {
    $min-px-size: math.div($px-size, $design-width) * $breakpoint-md;
  }
  @if not $max-px-size {
    $max-px-size: math.div($px-size, $design-width) * $breakpoint-xl;
  }
  $unitless-vw-size: math.div($px-size, $design-width) * 100;
  $scaled-size: #{$unitless-vw-size + 'vw'};

  font-size: clamp($min-px-size, $scaled-size, $max-px-size);
}

@mixin vh-font-size(
  $px-size,
  $min-px-size: null,
  $max-px-size: null,
  $design-height
) {
  $unitless-vh-size: math.div($px-size, $design-height) * 100;
  $scaled-size: #{$unitless-vh-size + 'vh'};

  font-size: clamp($min-px-size, $scaled-size, $max-px-size);
}

@mixin header-1 {
  font-family: var(--font-primary);
  @include vw-font-size(64px);
  font-weight: 400;
  line-height: 1.12;
}

@mixin header-2 {
  font-family: var(--font-primary);
  font-size: 36px;
  font-weight: 400;
  line-height: 1.05;

  @include md_ {
    @include vw-font-size(36px);
  }
}

@mixin header-2-small {
  font-family: var(--font-primary);
  font-size: 32px;
  font-weight: 400;
  line-height: 1.05;

  @include md_ {
    @include vw-font-size(36px);
  }
}

@mixin sectionHeadingV2 {
  font-family: var(--font-primary);
  font-size: 24px;
  font-weight: 400;
  line-height: 1.05;

  @include md_ {
    @include vw-font-size(36px);
  }
}

@mixin header-3 {
  font-family: var(--font-primary);
  font-size: $root-font-base-phone-size;
  font-weight: 700;
  line-height: 1.2;

  @include md_ {
    @include vw-font-size($root-font-base-size);
  }
}

@mixin content-type-1 {
  font-size: $root-font-base-phone-size;
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 1.2;

  @include md_ {
    @include vw-font-size($root-font-base-size);
  }
}

@mixin content-type-2 {
  font-size: 14px;
  font-family: var(--font-secondary);
  font-weight: 400;
  line-height: 1.2;

  @include md_ {
    @include vw-font-size(14px);
  }
}

@mixin content-type-3 {
  font-size: 24px;
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 1.2;

  @include md_ {
    @include vw-font-size($root-font-base-size);
  }
}

@mixin content-type-4 {
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 1.2;

  @include md_ {
    @include vw-font-size(14px);
  }
}
