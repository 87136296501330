.is-fonts-loaded .swiper-horizontal > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
  width: percentage(1/2);
  left: 50%;
  transform: translateX(-50%);
  height: rem-property(1px);
  background: #D3D3D3;
}
.is-fonts-loaded .swiper-scrollbar-drag {
  background: $black;

}