.video-js {
  video {
    // safari didn't seem to have the same dimensions as chrome (few pixels diff)
    // use "cover" to mask that bug?
    object-fit: cover;
  }
  &:focus,
  &:hover {
    @media (hover: hover) {
      .vjs-big-play-button {
        background-color: transparent;
        transition: none;
      }
    }
  }
  .vjs-big-play-button {
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    font-size: rem-property(100px);
  }
  .vjs-poster.element-child {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .vjs-big-play-button {
    background-color: transparent;
    transition: none;

    .vjs-icon-placeholder::before {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .vjs-control-bar {
    background-color: transparent;
    // bottom: calc(-1rem - 2px);
    bottom: 0;
  }

  .vjs-slider {
    background-color: transparentize(#fff, 0.5);
  }
  .vjs-progress-control .vjs-progress-holder {
    margin: 0;
    background: white;
  }
  .vjs-load-progress {
    background-color: transparentize(#fff, 0.5);

    div {
      background-color: transparentize(#fff, 0.5);
    }
  }

  .vjs-button > .vjs-icon-placeholder:before {
    font-size: 20px;
    line-height: 1.67;
  }

  .vjs-play-progress {
    background-color: black;
    position: relative;
    &:before {
      background-color: black;
      // height: 3px;
      width: 0px;
      bottom: 0;
      right: -6px;
      top: 0;
      content: '';
    }
  }
  .vjs-time-tooltip {
    display: none;
    opacity: 0;
    width: 0;
  }

  &.vjs-ended {
    .vjs-progress-control {
      opacity: 0 !important;
    }
  }
  .vjs-mouse-display {
    width: 10px;
  }

  .vjs-progress-holder {
    transform-origin: bottom;
    height: 10px;
    // @include md_ {
    height: 0.3em;
    // }
  }
  .vjs-progress-control:hover .vjs-progress-holder {
    transform: scaleY(4);
    font-size: 1em;
  }
  .vjs-progress-control {
    align-items: flex-end;
  }
  .vjs-progress-control .vjs-mouse-display {
    width: 2px;
  }
}
