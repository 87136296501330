html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
}

fieldset {
  border: 0;
}

body {
  position: relative;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

input {
  border-radius: 0; // This fixes rounded border on iOS/Safari
  font: inherit;
  margin: 0; // fixes wonky inline buttons on safari
}

button {
  border: none;
  cursor: pointer;
  font: inherit;
}

img {
  width: 100%;
}
a {
  // @include unstyled-link;
  color: inherit;
  @include clear-anchor-style;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}
