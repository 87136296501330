@use 'sass:math';

:root {
  --vh: 100vh;
  --vw: 100vw;
  --standard-columns: 11;
  --standard-gutter: 0.6vw;
  --standard-inset: 0.6vw;

  --standard-margin: 20px;

  // default settings for padding/margins
  --space-small: #{rem-property(100px)};
  --space: #{rem-property(160px)};

  // DEV NOTE: dynamicly set based on transition type
  // has to be MS
  --pageTransitionTime: 8000ms;
  --standardTransitionTime: 700ms;
  // --menuSlideTime: 1s;
  // --menuSlideDelay: 0.5s;

  // --slideWidth: 100px;
  // --slideHeight: 100px;
  // --slideMargin: 31px;

  --header-height: ((var(--standard-gutter) * 4) + 1rem);
  --logo-ratio: #{math.div($svg-height, $svg-width)};

  --logo-height: 126px;
  --logo-width: 433px;
  --small-logo-height: 63px;
  --small-logo-width: 215px;

  --contact-image-height: 120vh;

  --max-width: #{$max-width};

  @include _sm {
    --standard-columns: 4;
    --standard-gutter: 2vw;
    --standard-inset: 2vw;

    --space: #{rem-property(100px)};
    --space-small: #{rem-property(30px)};
  }
  @include md_ {
    --pageTransitionTime: 1000ms;
    --standard-margin: #{rem-property(40px)};
  }
}
